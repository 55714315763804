@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Bold.woff') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../css/Graphik/Graphik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('../css/Graphik/Graphik-Super.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.slider {
  background: red;
  width: 100px;
  height: 400px;
  position: absolute;
  left: 0;
  transition: left 0.3s linear;
  z-index: -1;
}

/*22-01-2025 thank-you page blog css */
.blog_date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.around_padding {
  padding: 16px !important;
}
.thank_you_new_page .blog_box_details {
  padding: 0px 10px 14px !important;
}
.thank_you_new_page .blog_box_details span {
  font-size: 16px !important;
}

/* 25-01-2025 */
.ios_ecosystem .slider_info .owl-nav {
  position: absolute;
  right: 0;
}

/* react9 */
.slider {
  z-index: 1 !important;
}

.thankyou_modal .modal-dialog-centered {
  display: flex;
  align-items: center;
}
div#thankyou_popup {
  top: 0 !important;
}
header.main_header.header.home_video_wrap.home_header_wrap {
  background: #fff !important;
}

/*career page css*/
.input.form-control {
  background: unset !important;
}
/*for tech-stack*/
.last_tab {
  padding-bottom: 500px; /* Adds space after the 13th tab */
}

/*animation */
/* 29-01-2025  portfolio animation */

.linear::before {
  content: '';
  position: absolute;
  width: 0%; /* Start from 0% width */
  height: 5px;
  top: -0.5px;
  left: 10px;
  background: #ff7a2f;
  animation: slide 5.9s cubic-bezier(0.9, 0.9, 0, 0) infinite;
  border-radius: 2px;
  z-index: 9;
}

@keyframes slide {
  0% {
    width: 0%; /* Starting with no width */
  }
  100% {
    width: 99%; /* Expand fully to the right */
  }
}
/* react9 */
.slider {
  z-index: 1 !important;
}
/*end*/

/* 404 page  */
.error_section_main {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 100px;
  padding: 50px 0 57px;
}
.no_padding {
  padding: 0;
}
.error_section_main .img_align_right {
  text-align: center;
}
.error_section_main img {
  max-width: 100%;
}
.error_section_main h2.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  color: #100f70;
  margin: 0;
}
.error_section_main .desc_txt {
  margin: 25px 0 50px;
}
.desc_txt {
  font-size: 18px;
  line-height: 30px;
  color: rgba(59, 59, 59, 0.8);
  margin-bottom: 30px;
  text-align: left;
}
.theme_btn_div {
  margin-bottom: 40px !important;
}
.theme_btn_div {
  float: left;
  width: 100%;
}
.error_section_main .theme_btn {
  font-weight: 600;
}
.theme_btn {
  width: 215px;
  height: 51px;
  line-height: 44px;
  background: #2583e9;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid transparent;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  cursor: pointer;
}
@media screen and (max-width: 991.98px) {
  .error_section_main h2.heading {
    padding-top: 20px;
  }
  .error_section_main {
    text-align: center;
  }
  p.desc_txt {
    text-align: center;
  }
}
/* end/
/* service page */
.play_icon_btn {
  background: rgba(255, 255, 255, 0.6) !important;
  border-radius: 50px !important;
}


/* 29-01-2025  portfolio animation */

.linear::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 5px;
  top: -0.5px;
  left: 10px;
  right: 10px;
  background: linear-gradient(90deg, #ff7a2f, #ff3b2f);
  animation: slide 6s ease-in-out infinite;
  border-radius: 50px;
  z-index: 9;
  box-shadow: 0px 0px 8px rgba(255, 122, 47, 0.6);
}

@keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: calc(100% - 20px);
  }
}